@use '../../styles/styles';

div#navDim {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: #0005;
    backdrop-filter: blur(15px);
    pointer-events: none;
    user-select: none;
    transition: all 0.2s styles.$bezier;

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}

nav {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 2.25rem 2.625rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    /* Logo */
    a img {
        width: 100%;
        max-width: 200px;
        display: flex;
        align-items: center;
    }

    /* Hamburger menu */
    div.menu {
        display: none;
    }

    /* Nav links */
    ul {
        display: flex;
        list-style: none;
        
        li {
            height: fit-content;

            a:link, a:visited {
                color: #fff;
            }

            &:not(:last-child) {
                margin-right: 3rem;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    nav {
        transition: all 0.2s styles.$bezier;
        padding-block: 2.25rem;

        div.menu {
            display: flex;
            flex-wrap: wrap;
            height: 30px;
            width: 30px;
            align-content: center;

            div {
                width: 30px;
                height: 3px;
                border-radius: 2px;
                background-color: #fff;
                display: block;
                transition: all 0.2s styles.$bezier;

                &:nth-child(2) {
                    margin-block: 6px;
                }
            }
        }
            
        ul {
            display: unset;
            order: 2;
            margin-top: 1rem;
            flex-basis: 100%;

            li {
                margin-top: 1rem;
                a:link, a:visited {
                    color: #000;
                    opacity: 0;
                    pointer-events: none;    
                }

                /* disable underline animation */
                &.underline::after {
                    display: none;
                }
            }
        }
   
    }

    nav.active {
        transition: all 0.2s styles.$bezier;

        background-color: white;
        color: #000;

        div.menu {
            div {
                width: 30px;

                &:nth-child(1) {
                    background-color: #000;
                    transform: translateY(3px) rotate(45deg) ;
                }

                &:nth-child(2) {
                    margin: 0;
                    opacity: 0;
                }

                &:nth-child(3) {
                    background-color: #000;
                    transform: translateY(-3px) rotate(-45deg);
                }
            }
        }

        a img {
            filter: invert(1)
        }

        ul {
            li {
                a:link, a:visited {
                    opacity: 1;
                    pointer-events: unset;
                }
            }
        }

    }
}

@media screen and (max-width: 380px) {
    nav {
        padding-inline: 1.25rem;
    }   
}