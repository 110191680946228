@use '../../styles/styles';

div.formContainer {
    padding: 2rem 4rem;
    background-color: styles.$secondary-bg;
    width: 100%;
    min-height: 300px;
    border-radius: 1rem;
    transition: all 0.2s styles.$bezier;

    div.formResponse {
        flex-wrap: wrap;

        h1 {
            flex-basis: 100%;
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }

        h1, p {
            text-align: center;
            max-width: 600px;
        }
    }
}

form#ContactForm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: min(100%, 850px);

    div.center {
        flex-wrap: wrap;
        flex-basis: 100%;
        margin: 0;
    }

    div.formHeader {
        h1 {
            flex-basis: 100%;
            font-size: 1.8rem;
        }

        p {
            margin-block: 1rem 1.8rem;
        }

        h1, p {
            text-align: center;
            max-width: 600px;
        }
    }

    input, textarea {
        margin-top: 1rem;
        background: styles.$primary-bg;
        border: styles.$primary-border;
        border-radius: 12px;
        padding: 1rem;
        font-size: 0.8rem;
        outline: none;
    
        &:focus {
            border-color: styles.$tertiary-bg;
        }
    }

    input {
        height: 3.75rem;
    }

    input[type=text], input[type=tel] {
        flex-basis: 49%;
    }

    input[type=email], textarea {
        flex-basis: 100%;
    }

    input[type=submit] {
        width: min(100%, 200px);
        height: 55px;
        border-radius: 40px;
        background-color: styles.$tertiary-bg;
        color: styles.$tertiary-txt;
        text-transform: uppercase;
        border: 1px solid transparent;
        cursor: pointer;
        transition: all 0.2s styles.$bezier;
        
        &:hover {
            background-color: rgba(styles.$tertiary-bg, 0.8);
            transform: scale(1.025);
        }

        &:active {
            background-color: rgba(styles.$tertiary-bg, 0.9);
            transform: scale(0.975);
        }
    }

    div.formError {
        margin-top: 1rem;

        p {
            padding: 0.25rem 0.75rem;
            width: fit-content;
            color: #fff;
            background-color: #eb3941;
            border-radius: 1rem;
            text-align: center;
        }
    }

}

@media only screen and (max-width:675px) {
    div.formContainer {
        padding-inline: 1rem;
    }

    form#ContactForm {
        input[type=text], input[type=tel] {
            flex-basis: 100%;
        }
        
        input, textarea {
            width: 100%;
        }
    }
}

.formFade {
	-webkit-animation: formFade 0.125s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
    animation: formFade 0.125s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
}

@-webkit-keyframes formFade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes formFade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}