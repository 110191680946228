$primary-bg: #fff;
$primary-txt: #000;
$primary-alt-txt: #674f97;
$primary-border: 1px solid #BAD;

$secondary-bg: #f8f4ff;
$secondary-txt: #000;

$tertiary-bg: #5a2abb;
$tertiary-txt: #fff;

$bezier: cubic-bezier(.17,.67,.71,1);