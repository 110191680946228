section#AboutUs {
    text-align: center;

    p {
        margin-bottom: 2rem;
    }

    img {
        object-fit: cover;
        height: 450px;
        width: 100%;
        border-radius: 25px;
    }
}