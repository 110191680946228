@use '../../styles/styles';

section#Home {
    height: max(85vh, 600px);
    padding: 4rem 2rem 0;
    background: no-repeat url(../../../public/assets/images/hero-wide.webp) bottom right;
    background-size: cover;

    >div {
        height: 100%;
        width: min(100%, 650px);
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        h1, p {
            margin-inline: 0 auto;
            color: #fff;
        }

        h1 {
            font-size: 3.375rem;
            font-weight: 600;
            line-height: 1.25;
        }

        p {
            font-size: 1.125rem;
            font-weight: 300;
            margin-block: 2rem;
        }

        a {
            width: fit-content;
            display: flex;
            align-items: center;
            padding: 1rem 2rem;
            border: solid 1px #fff;
            border-radius: 2rem;
            transition: none 0.2s styles.$bezier;
            transition-property: background-color, transform;

            &:link, &:visited {
                color: #fff;
            }

            svg {
                margin-right: 0.5rem;
            }

            &:hover {
                background-color: #fff;
                color: #000;
                mix-blend-mode: screen;
            }

            &:active {
                transform: scale(0.975);
            }
        }
    }
}

@media only screen and (max-width:1100px) {
    section#Home {
        >div {
            width: min(100%, 500px);

            h1 {
                font-size: 2.75rem;
            }
        }
    }
}

@media only screen and (max-width:900px) {
    section#Home {
        height: max(85vh, 700px);
        background-image: url(../../../public/assets/images/hero-tall.webp);
        background-position: center bottom;
        background-position-y: -500px;

        >div {
            padding-top: 4rem;
            align-content: start;
        }
    }
}

@media only screen and (max-width:700px) {
    section#Home {
        background-position-y: -400px;
    }
}

@media only screen and (max-width:600px) {
    section#Home {
        background-position-y: 100%;
    }
}

@media only screen and (max-width:450px) {
    section#Home {
        >div {
            h1 {
                font-size: 2.375rem;
            }
        }
    }
}

@media only screen and (max-width:380px) {
    section#Home {
        padding-inline: 1rem;
        height: 800px;
    }
}