@use '../../styles/styles';

section#SatisfiedClients {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h2 {
        margin-bottom: 2rem;
        flex-basis: 100%;
        font-size: 1rem;
        font-weight: 500;
        color: styles.$primary-txt;
        text-align: center;
    }

    img.desktop {
        width: min(100%, 1200px);
    }

    img.mobile {
        display: none;
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    section#SatisfiedClients {
        p {
            margin-bottom: 1rem;
        }

        img.desktop {
            display: none;
        }

        img.mobile {
            display: block;
        }
    }
}
