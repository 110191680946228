// Uncomment to clearly see all divs under main
// main.index {
//     background-color: #f003;
    
//     section {
//         background-color: #00f3;
//     }
// } 
@use '../../styles/styles';

main.index {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: styles.$primary-bg;

    section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: min(100% - 4rem, 1200px);
        @media screen and (max-width: 800px) {
            width: calc(100% - 2rem);
        }
        margin-bottom: 5rem;

        &:first-child {
            margin-top: 2rem;
        }

        >h1 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 2rem;
            text-transform: capitalize;
            text-align: center;
        }

        >p {
            font-weight: 1rem;
            font-weight: 300;
        }
    }
}

img {
    pointer-events: none;
}

.pm0 {
    margin: 0;
    padding: 0;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
