main.privacy {
    background-color: #f6f6f6;
    padding: 2rem 0;
    margin: 0;

    section {
        margin: 2rem auto 0;
        width: min(100% - 4rem, 1200px);
        color: #333;

        &:first-child {
            margin-top: 0;
        }

        h1, h2 {
            font-weight: 400;
        }

        h1 {
            font-size: 2.125rem;
        }

        h2 {
            font-size: 1.75rem;
        }

        p {
            margin-top: 0.8rem;
        }

        ul {
            margin-top: 0.5rem;
            margin-left: 2.8rem;
            font-size: 0.875rem;

            li {
                margin-top: 0.25rem;
            }
        }
    }
}

#copyright.privacy {
    background-color: #f6f6f6;
}