@use '../../styles/styles';

footer.index {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 4rem;
    background-color: styles.$tertiary-bg;
    
    color: styles.$tertiary-txt;
    a:link, a:visited {
        color: styles.$tertiary-txt
    }

    h2 {
        font-size: 1.125rem;
        font-weight: 400;
    }

    p, li, a {
        font-size: 0.875rem;
        font-weight: 300;
    }
    
    /* Icons */
    a svg {
        height: 1.125rem;
        width: 1.125rem;
    }

    /* Sections */
    > div {
        /* Margin after section title/image */
        >:first-child {
            margin-bottom: 1rem;
        }
        
        &.description {
            img {
                width: 100%;
            }

            > a {
                display: block;
                height: fit-content;
                width: min(100%, 150px)
            }

            width: 350px;
        }

        &.info {
            text-align: center;
            > a {
                margin: auto;
                display: flex;
                margin-bottom: 0.75rem;
                width: fit-content;
                
                svg {
                    margin-right: 0.5rem;
                }
            }
            .socials {
                display: flex;
                justify-content: center;
                a {
                    display: block;
                    transition: transform 0.2s styles.$bezier;

                    &:not(:first-child) {
                        margin-left: 0.5rem
                    }    

                    &:hover {
                        transform: scale(1.125);
                    }
                }
            }
        }

        &.links {
            text-align: center;

            ul {
                list-style: none;

                li {
                    margin: auto;
                    width: fit-content;
                    user-select: none;

                    &:not(:first-child) {
                    margin-top: 0.75rem;
                    }
                }
            }
        }

        &.newsletter {
            text-align: center;
            width: 230px;

            form {
                display: flex;

                input {
                    height: 50px;
                    background-color: #fff;
                    border: 0;
                    outline: none;
                    color: #000;

                    &::placeholder {
                        color: #000;
                        opacity: 1;
                    }

                    &[type=email] {
                        width: calc(100% - 50px);
                        border-radius: 9px 0px 0px 9px;
                        text-indent: 45px;
                        /* color, image, repeat, pos-x, pos-y */
                        background: #fff url('https://api.iconify.design/ic/outline-email.svg') no-repeat 17px 16px;
                    }

                    &[type=submit] {
                        width: 50px;
                        border-radius: 0 9px 9px 0;
                        border-left: 1px solid styles.$tertiary-bg;
                    }
                }
            }
        }
    }
}

/* max 2 wide - medium screens */
@media screen and (max-width: 1200px) {
    footer.index {
        padding: 2rem 2rem;

        > div {
            flex-basis: calc(100% / 3);

            &.description {
                flex-basis: 100%;
                margin: 0 auto 2rem;
                text-align: center;

                a, p {
                    margin: auto;
                }

                p {
                    width: min(100%, 500px);
                }
            }
        }
    }
}

/* 1 wide - small screens/mobile */
@media screen and (max-width: 750px) {
    footer.index {
        padding: 2rem 1rem;

        > div {
            justify-content: center;
            flex-basis: 100%;

            &:not(:last-child) {
                margin-bottom: 2rem;
            }

            &.links {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    width: min(100%, 400px);
                    margin: auto;

                    > li {
                        margin: 0.25rem 1rem !important;
                    }
                }
            }

        }

        form {
            width: min(100%, 350px);
            margin: auto;
        }
    }
}