@use './styles/styles';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    /* Override native appearances */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

html, body {
    overflow-x: hidden;
    position: relative;
}

.wrapp-container {
    width: 100%;
    max-width: 1450px;
    margin: auto;
    height: 100%;
}

a {
    text-decoration: none;
}

p {
    line-height: 1.6em;
}

#backToTop {
    visibility: hidden;
    line-height: 0;
    font-size: 1.125rem;
    padding: 15px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    background: styles.$primary-bg;
    opacity: 0;
    color: #000;
    border: styles.$primary-border;
    border-radius: 6px;
    
    cursor: pointer;
    transition: all 0.2s styles.$bezier;

    &:hover {
        padding: 16px;
        right: 19px;
        bottom: 19px;
        background-color: styles.$tertiary-bg;
        border: 1px solid styles.$primary-bg;
        color: styles.$tertiary-txt;
    }
}

#copyright {
    padding-block: 0.3rem;
    
    text-align: center;
    font-weight: 300;
    font-size: 0.875rem;

    :link, :visited {
        color: styles.$tertiary-bg;
    }

    .underline::after {
        background-color: styles.$tertiary-bg;
        bottom: -0.5px;
    }
}

.point {
    cursor: pointer;
}