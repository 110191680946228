@use '../../styles/styles';

section#FAQ {
    div.accordion {
        width: 100%;
        margin: auto;
        border: 2px solid styles.$secondary-bg;
        border-radius: calc(0.75rem + 2px);

        &:not(:first-child) {
            margin-top: 1rem;
        }

        p.question {
            background-color: styles.$secondary-bg;
            font-size: 1rem;
            width: 100%;
            padding: 1rem;
            padding-left: 30px;
            user-select: none;
            border-radius: 0.75rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(.active) {
                transition: border-radius 0.1615s cubic-bezier(1,0,1,0);
            }

            &.active {
                border-radius: 0.75rem 0.75rem 0 0;
            }

            span {
                margin-left: 1rem;
            }
        }

        .answer {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.2s styles.$bezier;
            font-weight: 300;
            font-size: 15px;

            &.active {
                grid-template-rows: 1fr;
            }

            div {
                overflow: hidden;

                p {
                    padding: 1rem;
                    padding-left: 30px;
                    font-size: 15px;
                    font-weight: 300;
                }
            }
        }
    }
}