@use '../../styles/styles';

/***************** TESTIMONIAL CARDS STYLING  *******************/
.testimonial-card {
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 66px;
    height: 229px;
}

/***************** CLIENT IMAGES STYLING  *******************/
.client-img {
    display: inline;
    height: 229px;

    img {
        object-fit: cover;
        height: 229px;
        border-radius: 20px 0 0 20px;
    }
}

/***************** TESTIMONIAL STYLING  *******************/
.testimonial {
    height: 100%;
    border: styles.$primary-border;
    border-radius: 0 20px 20px 0;
    margin-left: 15px;
    padding: 0px 45px 0px 35px;

    .quote {
        height: 0px;
        display: inline-block;
        font-size: 4.375rem;
        color: styles.$primary-alt-txt;
    }

    .description {
        font-weight: 300;
        font-size: 0.875rem;
        color: styles.$primary-txt;
    }
}

.client-info {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    color: styles.$primary-alt-txt;
    padding: 10px 0 15px 0;

    span {
        font-weight: 400;
    }

    a {
        margin-left: 0.35em;
        display: flex;
        align-items: center;
        
        img {
            max-height: 1em;
            max-width: 1em;
        }
    }
}

/******************* MEDIA QURIES START **********************/

@media only screen and (max-width:1300px) {
    .testimonial-container .head h1 {
        font-size: 30px;
    }

    .testimonial-card {
        height: 254px;
    }

    .client-img {
        height: 254px;
        
        img {
            height: 254px;
        }
    }

    .testimonial {

        .description {
            font-weight: 400;
            font-size: 15px;
        }

        .client-info {
            font-weight: 500;
            font-size: 16px;
            padding: 10px 0 10px 0;
        }
    }
}

@media only screen and (max-width:1100px) {
    .testimonial-container .head h1 {
        font-size: 28px;
    }

    .testimonial-card {
        height: 323px;
    }

    .client-img {
        height: 323px;
        
        img {
            height: 323px;
        }
    }

    .testimonial {
        padding: 0px 45px 10px 35px;

        .description {
            font-size: 14px;
        }
    }


    .client-info {
        font-size: 15px;
        padding: 10px 0 10px 0;
    }
}

@media only screen and (max-width:900px) {
    .testimonial-card {
        height: unset;
        flex-direction: column;
        text-align: center;
    }

    .client-img {
        height: unset;
        width: 100%;

        img {
            max-width: 90%;
            height: 450px;
            object-fit: cover;
            width: 100%;
            border-radius: 20px 20px 0 0;
        }
    }

    .testimonial {
        border: styles.$primary-border;
        border-radius: 0 0 20px 20px;
        margin-left: 0;
        padding: 0px 25px 0px 35px;
        width: 90%;
    }

    .testimonial .description {
        font-size: 13px;
    }

    .swiper .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }
}

@media only screen and (max-width:800px) {
    .client-img img {
        height: 375px;
    }
}

@media only screen and (max-width:600px) {
    .testimonial-card {
        margin: 0 50px;
    }

    .client-img  img {
        max-width: 100%;
        height: 250px;
        border-radius: 8px 8px 0 0;
    }

    .testimonial {
        width: 100%;
    }
}

@media only screen and (max-width:500px) {
    .testimonial-card {
        margin: 0 20px;
    }

    .testimonial {
        padding: 0px 15px 0px 15px;
    }
}

@media only screen and (max-width:400px) {
    .testimonial-card {
        margin: 0 10px;
    }

    .client-img img {
        height: 200px;
    }

    .client-info {
        font-size: 15px;
        padding: 15px 0 15px 0;
    }
}