@use './styles';

.swipeRight {
    animation: swipeRight 0.8s ease 200ms backwards;
}

@keyframes swipeRight {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translate(0px);
    }
}

.fadeIn {
    animation: fadeIn 0.5s ease 200ms backwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.underline {
    position: relative;;

    &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #fff;
        bottom: -2.5px;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.2s styles.$bezier;
    }   
}