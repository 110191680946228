@use '../../styles/styles';

section#Services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div.container {
        width: 100%;
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .card {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            margin: 0;
            padding: 0;
            width: 370px;
            border-radius: 1rem;
            border: styles.$primary-border;
            transition: all 0.2s styles.$bezier;

            img {
                width: 100%;
                height: max-content;
                border-radius: 1rem 1rem 0 0;
                border-bottom: styles.$primary-border;
            }

            div {
                width: 100%;
                padding: 1.5rem 2rem;
                h2 {
                    color: styles.$primary-alt-txt;
                    font-size: 1.125rem;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }
                p {
                    font-size: 0.875rem;
                    font-weight: 300;    
                }
            }

            &:hover {
                transform: scale(1.03);
            }
        }

    }

    /* Button */
    a {
        padding: 1rem 2.5rem;
        border: styles.$primary-border;
        border-radius: 2rem;    
        color: styles.$primary-alt-txt;
        text-transform: uppercase;
        transition: none 0.2s styles.$bezier;
        transition-property: background-color, border-color, transform;

        &:hover {
            color: styles.$tertiary-txt;
            background-color: styles.$tertiary-bg;
            border-color: styles.$tertiary-bg;
        }

        &:active {
            background-color: rgba(styles.$tertiary-bg, 0.9);
            border-color: rgba(styles.$tertiary-bg, 0.9);
            transform: scale(0.975);
        }
    }
}

/* 2 cards wide */
@media only screen and (max-width:1225px) {
    section#Services {
        div.container {
            .card {
                width: max(50% - 1rem, 375px);  

                &:not(:last-of-type) {
                    margin-bottom: 2rem;
                }

                &:last-of-type {
                    margin-inline: auto;
                }
            }
        }
    }
}

/* 1 card wide */
@media only screen and (max-width:850px) {
    section#Services {
        div.container {
            .card {
                margin-inline: auto;
                width: min(100%, 500px);
            }
        }
    }
}
