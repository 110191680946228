div.swiper {
    padding-bottom: 50px;
    --swiper-navigation-color: #fff;
    --swiper-theme-color: #5a2abb;
    --swiper-theme-color-hover: #5a2abbdd;
    --swiper-navigation-sides-offset: 0
}

/* Nav buttons */
div.swiper .swiper-button-prev, div.swiper .swiper-button-next {
    top: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 6px;
    background-color: var(--swiper-theme-color);
    color: #fff;
    transition: all 0.2s cubic-bezier(.17,.67,.71,1);
}
div.swiper .swiper-button-prev::after, div.swiper .swiper-button-next::after {
    font-size: 1.125rem;
    font-weight: 700;
}
div.swiper .swiper-button-prev:hover, div.swiper .swiper-button-next:hover {
    background-color: var(--swiper-theme-color-hover);
}
 